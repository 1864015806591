<template>
  <div class="row">
    <div class="col-4 q-pa-sm">
      <q-radio v-model="add" :val="false" checked-icon="task_alt" label="Списать" unchecked-icon="panorama_fish_eye" />

      <q-radio v-model="add" :val="true" checked-icon="task_alt" label="Начислить" unchecked-icon="panorama_fish_eye" />
    </div>

    <div class="col-4">
      <q-item>
        <q-select v-model="organizationId" :option-label="value => organizationsLabelMap[value]"
          :options="organizationsFiltered" :rules="[val => $validators.positiveNumber(val) || 'Обязательно к заполнению']"
          class="full-width" clearable dense input-debounce="0" label="Источник" outlined use-input
          @filter="filterOrganizations" />
      </q-item>
    </div>

    <div class="col-4">
      <q-item>
        <q-input v-model="amount" :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']"
          class="full-width" dense label="Сумма в &#8381" outlined type="number">
          <template v-slot:prepend>
            <q-icon name="currency_ruble" />
          </template>
        </q-input>
      </q-item>
    </div>

    <div class="col-8">
      <q-item>
        <q-input v-model="description" :rules="[val => !!val || 'Обязательно к заполнению']" autogrow class="full-width"
          clearable dense label="Текст для истории операций" outlined />
      </q-item>
    </div>

    <div class="col-4">
      <q-item>
        <q-btn class="full-width" color="accent" icon="checkmark" label="Применить" @click="charge" />
      </q-item>
    </div>

  </div>
</template>

<script setup>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { PaymentsApi } from '@/api/apis/payments-api.ts';
import { Notify } from 'quasar';
import {
  computed, defineEmits, defineProps, ref,
} from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue', 'updated']);

const entity = computed(() => props.modelValue);

const add = ref(false);
const organizationId = ref(0);
const amount = ref(0);
const description = ref('');

const organizationsFiltered = ref([]);
const organizations = ref([]);
const organizationsLabelMap = ref({});

new AdminApi().organizationControllerFindOrganizations().then(({ data }) => {
  organizations.value = data.map((item) => item.account_no);

  data.forEach((item) => {
    organizationsLabelMap.value[item.account_no] = item.name;
  });
});

const filterOrganizations = (val, update) => {
  if (val === '') {
    update(() => {
      organizationsFiltered.value = organizations.value;
    });
    return;
  }

  update(() => {
    const needle = val.toLowerCase();
    organizationsFiltered.value = [];
    for (const id in organizationsLabelMap.value) {
      if (organizationsLabelMap.value[id].toLowerCase().has(needle)) {
        organizationsFiltered.value.push(id);
      }
    }
  });
};

const charge = async () => {
  try {
    const body = {
      amount: amount.value,
      source: 0,
      recipient: 0,
      description: description.value,
      capture: true,
    };

    if (add.value === true) {
      body.recipient = entity.value.account_no;
      body.source = organizationId.value;
    } else {
      body.source = entity.value.account_no;
      body.recipient = organizationId.value;
    }

    await new PaymentsApi().transactionControllerMakeCharge(body);

    Notify.create('Транзакция отправлена');

    emit('updated');
  } catch (e) {
    console.error(e);
  }
};
</script>
