<template>
  <q-table v-model:pagination="pagination" :loading="loading" :rows="rows" :rows-per-page-options="[3, 10, 50, 100, 1000]"
    class="full-width" separator="none" @request="onRequest">
    <template v-slot:top></template>
    <template v-slot:header>
      <div class="text-overline q-px-md">
        История операций
        <q-btn class="q-mx-sm float-right" color="secondary" icon="refresh" label="" size="sm" @click="() => onRequest()" />
      </div>
    </template>

    <template v-slot:body="{ row }">
      <div class="full-width q-ma-xs q-pa-xs">
        <q-item>
          <q-item-section>
            <q-item-label caption>{{ $filters.toHumanDateTime(row.datetime) }}</q-item-label>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ row.description }}</q-item-label>
          </q-item-section>

          <q-item-section class="text-left" side>
            <q-item-label v-if="row.negative">
              <q-icon color="red" name="shopping_cart" />

              Списание
            </q-item-label>

            <q-item-label v-if="!row.negative">
              <q-icon color="green" name="add_circle" />
              Начисление
            </q-item-label>

          </q-item-section>

          <q-item-section side style="width: 200px;">
            <q-item-label :class="{ 'text-red': row.negative, 'text-green': !row.negative }" class="text-h6">
              {{ row.negative ? '-' : '+' }}
              {{ row.transaction.amount }} &#8381
            </q-item-label>
          </q-item-section>
        </q-item>

      </div>
    </template>

  </q-table>
</template>

<script setup>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { debounce } from 'quasar';
import {
  computed, defineEmits, defineProps, onMounted, ref, watch,
} from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue', 'updated']);

const entity = computed(() => props.modelValue);

const rows = ref([]);
const loading = ref(false);
const pagination = ref({
  sortBy: 'datetime',
  descending: true,
  page: 1,
  rowsPerPage: 3,
  rowsNumber: 10,
});

async function onRequest(params) {
  if (!entity.value?.account_no) {
    return;
  }

  params = params || {
    pagination: pagination.value,
  };

  const {
    page,
    rowsPerPage,
    sortBy,
    descending,
  } = params.pagination;

  loading.value = true;

  const response = await new AdminApi().operationControllerGetOperations(
    entity.value.rsv_user_id,
    (page - 1) * rowsPerPage,
    rowsPerPage,
  );
  let { data } = response.data;
  data = data.map((operation) => {
    const result = {
      ...operation,
      transaction: operation.transactions.filter(
        (t) => t.currency === 'dobroruble' && t.account_no === entity.value.account_no,
      ).pop(),
    };

    result.negative = result.transaction.relation === 'source';
    return result;
  });
  pagination.value.rowsNumber = response.data.count;

  rows.value = data;

  pagination.value.page = page;
  pagination.value.rowsPerPage = rowsPerPage;
  pagination.value.sortBy = sortBy;
  pagination.value.descending = descending;

  loading.value = false;
}

const onRequestDebounced = debounce(onRequest, 500);
onMounted(() => onRequestDebounced());
watch(() => entity.value, () => onRequestDebounced());

</script>
